<template>
  <div class="row incentive">
    <div class="col-auto d-none d-md-flex align-items-center">
        <img class="dollar" src="~Images/dollar_sign.svg" />
    </div>
    <div class="col">
      <p>
        <b>{{ incentive.title }}</b
        >: <span v-html="description"></span>
        <b v-show="amountDescription"
          >Typical amount: {{ amountDescription }}</b
        >
      </p>
    </div>
  </div>
</template>

<script>
import common from "Mixins/common";
export default {
  props: {
    incentive: Object,
  },
  computed: {
    description() {
      return this.$sanitize(this.incentive?.description) + " "; //adding space here allows in-line to be spaced but new line to be left-aligned
    },
    amountDescription() {
      if (this.incentive?.min_amount && this.incentive?.max_amount) {
        return `${common.toCurrency(
          this.incentive.min_amount
        )} - ${common.toCurrency(this.incentive.max_amount)}`;
      }
      if (this.incentive?.min_amount) {
        return `${common.toCurrency(this.incentive.min_amount)}+`;
      }
      if (this.incentive?.max_amount) {
        return `up to ${common.toCurrency(this.incentive.max_amount)}`;
      }
      return null;
    },
  },
};
</script>

<style lang="scss" scoped>
div.incentive {
  background: white;
  border: 10px solid #f2f2f2;
  font-family: $primary-header-font-family;
  padding-top: 20px;
  box-sizing: border-box;
  box-shadow: 0px 0px 32px 0px rgba(255, 255, 255, 0.51);
  color: #333333;
}

::v-deep th,
::v-deep td {
  padding: 5px;
}

img.dollar {
  margin-top: -22px;
}

</style>